


import React, { useState } from 'react';
import axios from 'axios';
import support from '../assets/support.png';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      // Handle success
      console.log('Form submitted successfully!');
      setFormData({ name: '', email: '', message: '' }); // Clear form fields

      // Show alert message after successful form submission
      alert("Form submitted successfully!");
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error submitting form:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="container mx-auto mt-8 mb-8 p-8 bg-gradient-to-r from-blue-300 via-purpe-600 to-custom-purple text-white rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold mb-6">Contact Us</h1>
      <p className="text-gray-300 mb-8">Feel free to reach out to us for any inquiries, feedback, or collaboration opportunities.</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div>
          <h2 className="text-2xl font-bold mb-4">Send us a Message</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-semibold text-gray-300">Your Name</label>
              <input type="text" id="name" name="name" className="w-full text-black bg-white border-2 p-3 rounded-md focus:outline-none focus:border-purple-500" value={formData.name} onChange={handleChange} />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-semibold text-gray-300">Your Email</label>
              <input type="email" id="email" name="email" className="w-full text-black bg-white border-2 p-3 rounded-md focus:outline-none focus:border-purple-500" value={formData.email} onChange={handleChange} />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block text-sm font-semibold text-gray-300">Your Message</label>
              <textarea id="message" name="message" rows="4" className="w-full text-black bg-white border-2 p-3 rounded-md focus:outline-none focus:border-purple-500" value={formData.message} onChange={handleChange}></textarea>
            </div>
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded-full focus:outline-none focus:shadow-outline-blue">Send Message</button>
          </form>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
          <p className="mb-2"><span className="font-semibold">Email:</span> shreegajanana.ent2021@gmail.com</p>

          <img src={support} alt="Contact Us" className="w-70 h-70 rounded-md shadow-lg" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
