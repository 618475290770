

import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import "../components/slider.css";
import sliderImage from '../components/sliderImage';
import studytap1 from '../assets/studytap1.png';
import digital from '../assets/digital.png';
import stnewslogo from '../assets/stnewslogo.jpg';
import tading from '../assets/trading.png';

import videoFile from '../assets/video.mp4';
import exportologo2 from '../assets/exportologo 2.png';
import itservice1 from '../assets/itservice1.png';
import studytap from '../assets/studytap.png';

import companylogo from '../assets/companylogo.png';
import { Link } from 'react-router-dom';
import '../logo.css';

// const len = sliderImage.length - 1;

const Home = () => {
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const handleScroll = (direction) => {
    const newIndex = direction === 'next' ? startIndex + 4 : startIndex - 4;
    setStartIndex(Math.max(0, Math.min(newIndex, totalImages.length - 4)));
  };


  const totalImages = [
    "https://placekitten.com/100/100?image=1",
    "https://placekitten.com/100/100?image=2",
    "https://placekitten.com/100/100?image=3",
    "https://placekitten.com/100/100?image=4",
    "https://placekitten.com/100/100?image=5",
    "https://placekitten.com/100/100?image=6",
    "https://placekitten.com/100/100?image=7",
    "https://placekitten.com/100/100?image=1",
    "https://placekitten.com/100/100?image=2",
    "https://placekitten.com/100/100?image=3",
    "https://placekitten.com/100/100?image=4",
    "https://placekitten.com/100/100?image=5",
    "https://placekitten.com/100/100?image=6",
    "https://placekitten.com/100/100?image=7",
    // Add more image URLs as needed
  ];


  const testimonies = [
    "STUDYTAP is an excellent app! Just two days before my exam, I took the subscription, prepared, and was pleasantly surprised in the exam hall—almost 75% of the questions were familiar. A big thanks to STUDYTAP for its effective and timely support, making a significant impact on my exam success.",
    "Exporto delivered an outstanding batch of green chillies to Dubai, surpassing my expectations in both quality and spiciness. The impeccable packaging ensured the produce arrived fresh and vibrant. Their commitment to excellence in both product and delivery makes Exporto my top choice for international trade. Highly recommended",
    "This is a remarkable application that has set new standards in the field of exam preparation. Its comprehensive study resources, personalized learning paths, collaborative features, and exceptional user experience make it a must-have tool for students striving for academic excellence. By harnessing the power of technology, STUDYTAP has revolutionized the way students approach exams, making studying not only effective but also enjoyable.",
    "It's a very innovative and user-friendly app. I enjoyed the experience because, while we can find question papers or previous papers from various sources, the idea of providing answers and explanations to those questions is amazing. I've read some concerns about pricing above, but providing educational content that can potentially change our careers for a price less than a movie ticket and popcorn in the multiplex is significant and worthwhile. I highly recommend this app to all those sensible career aspirants.",
    "Digital Marketing's services have been a game-changer for my business. Their strategic approach to social media, proactive engagement, and issue resolution have significantly boosted our online presence. The team's dedication to nurturing brand success is evident in the seamless customer satisfaction achieved. I highly recommend Digital Marketing for anyone seeking effective and results-driven digital marketing solutions."
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
  };
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonies.length) % testimonies.length);
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,  // Set autoplay to true
    autoplaySpeed: 1500,  // Set the autoplay speed in milliseconds (3 seconds in this case)
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const len = sliderImage.length - 1;
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const servicesData = [
    {
      id: 1,
      title: 'Studytap',
      description: 'Elevate your exam readiness with past papers, answers, and crucial questions in one app.',
      image: studytap, // Replace with the path to your service image
      link: '/studytap', // Replace with the path to the page for Service 1
    },
    {
      id: 2,
      title: 'Exporto',
      description: 'Bridging borders with excellence, specializing in the export of high-quality  and a variety of premium products across the globe',
      image: exportologo2, // Replace with the path to your service image
      link: '/exporto', // Replace with the path to the page for Service 2
    },
    {
      id: 3,
      title: 'It Service',
      description: ' Crafting satisfaction with web and app solutions, delighting over 25,000 + happy users.',
      image: itservice1, // Replace with the path to your service image
      link: '/it_services', // Replace with the path to the page for Service 3
    },
    {
      id: 4,
      title: 'Digital Marketing',
      description: 'Nurturing brand success through social media, proactive engagement, and issue resolution for seamless customer satisfaction.',
      image: digital, // Replace with the path to your service image
      link: '/digital_marketing', // Replace with the path to the page for Service 4
    },
    {
      id: 5,
      title: 'Studytap News ',
      description: ' Your go-to source for concise educational updates, providing tailored news for learners and educators.',
      image: stnewslogo, // Replace with the path to your service image
      link: '/ed', // Replace with the path to the page for Service 3
    },
    {
      id: 6,
      title: 'TWS',
      description: 'Stay tuned for our upcoming trading strategies implementation, designed to enhance your trading experience.',
      image: tading, // Replace with the path to your service image
      link: '/digital_marketing', // Replace with the path to the page for Service 4
    },
  ];

  return (
    <>
      {/* hero */}

      <section className="bg-black text-black py-7 md:py-10 lg:py-12">
        <div className="container mx-auto flex flex-col md:flex-row items-center">
          <div className="md:w-full lg:w-3/4 ml-4 md:ml-8 lg:ml-12 p-4">
          {/* <marquee direction="left" className="text-2xl md:text-4xl lg:text-5xl mb-6 text-custom-purple">Shree Gajanana Enterprises LLP</marquee> */}
            <h2 className="text-2xl md:text-4xl lg:text-5xl mb-6 text-custom-purple">Shree Gajanana Enterprises LLP</h2>
            <p className="mb-6 md:text-md text-white text-lg text-justify sm:pr-5 md:pr-3 lg:pr-6">
              Shree Gajanana Enterprises LLP, founded by Konijeti Mallikarjuna Prasad, stands as a beacon of excellence in the business world. Guided by a commitment to quality, the company, under the leadership of his son Konijeti Venkata Mahesh Gupta, focuses on four distinct services.<br/> <br/> StudyTap is your go-to solution for B.Tech exam prep. Access previous year's question papers and solutions from top universities like JNTUH and JNTUK with a simple tap. Trust us to provide the tools you need for success, and join us on our journey to empower scholars with knowledge and confidence.<br /> <br/>

              Exporto, our second service, reflects our dedication to delivering high-quality products to international markets. By maintaining stringent quality standards, we ensure our offerings meet the expectations of global consumers. In addition to our core services, we bring our expertise to the fields of IT services and digital marketing, offering holistic solutions to our clients.<br /><br/>

              In a competitive business environment, Shree Gajanana Enterprises LLP remains agile and innovative. With a focus on customer satisfaction, we strive to continuously adapt and exceed the diverse expectations of our clientele.
            </p>
            <Link to="/about">
              <button className="bg-custom-purple text-white py-2 px-4 rounded-full  focus:outline-none focus:shadow-outline">
                Know More &rarr;
              </button>
            </Link>
          </div>
                    <div className="mb-8 md:mb-0 w-full md:w-1/3 lg:w-1/4 text-center">
            <img
              src={companylogo}
              alt="Company Logo"
              className="w-full h-auto md:w-64 md:h-64 p-4 lg:w-full lg:h-full sm:w-12 sm:h-12 object-contain shadow-lg shadow-custom-purple bg-black mx-auto"
            />
          </div>


        </div>
      </section>



{/* services */}
<section className="py-6 lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 md:gap-6 sm:grid sm:grid-cols-1 sm:gap-4 pl-4 pr-4">
  <div className="lg:col-span-2 md:col-span-2 sm:col-span-1 text-center mb-6">
    <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold text-purple800 text-custom-purple">
      Our Services
    </h2>
  </div>
  {servicesData.map((service, index) => (
    <div
      key={service.id}
      className="lg:col-span-1 md:col-span-1 sm:col-span-1 mb-8 flex flex-col lg:flex-row items-center bg-white rounded-md shadow-lg shadow-gray-600 p-6"
    >
      <img
        src={service.image}
        alt={service.title}
        className={`w-full lg:w-64 h-48 object-cover  md:object-cover mb-4 lg:mb-0 lg:mr-4 ${index === 5 ? 'filter blur-sm' : ''}`}
      />
      <div className="text-center lg:text-left lg:flex-1">
        <h3 className="text-xl font-bold mb-2">{service.title}</h3>
        <p className="text-gray-500 mb-4">{service.description}</p>
        {index === 5 ? (
          <button className="bg-custom-purple text-white py-2 px-4 rounded-full cursor-not-allowed opacity-50 focus:outline-none focus:shadow-outline">
            Coming Soon
          </button>
        ) : (
          <Link to={service.link}>
            <button className="bg-custom-purple text-white py-2 px-4 rounded-full hover:bg-custom-purple focus:outline-none focus:shadow-outline">
              Know More &rarr;
            </button>
          </Link>
        )}
      </div>
    </div>
  ))}
</section>

{/* video */}
{/* <div className="text-center mt-8 mb-8 px-4 lg:px-12">
  <div className="relative">
    
    
    <iframe 
      width="560" 
      height="315" 
      src="https://www.youtube.com/embed/9zC-pe9V7aE" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen
      className="mx-auto rounded-md "
    ></iframe>
  </div>
</div> */}

<div className="text-center mt-8 mb-8 px-4 sm:px-6 md:px-8 lg:ml-20 lg:mr-20 xl:px-16">
  <div className="relative" style={{paddingTop: "56.25%"}}> {/* This maintains a 16:9 aspect ratio */}
    <iframe 
      width="100%" 
      height="100%" 
      src="https://www.youtube.com/embed/9zC-pe9V7aE" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen
      style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}} /* This makes the iframe cover the entire parent */
      className="mx-auto rounded-md"
    ></iframe>
  </div>
</div>




      {/* Testimonials */}
      <div className="text-center mb-8 pb-24 pt-12 bg-gradient-to-b from-custom-purple to-blue-200">
        <h1 className="text-4xl mb-8 font-bold text-white">Testimonials</h1>
        <div className="flex items-center justify-between" style={{ maxWidth: '100%' }}>
          <button
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className="rounded-full w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 focus:outline-none text-white"
          >
            &lt;
          </button>
          <div className="w-full lg:w-3/4 mb-6">
            <p className="text-base sm:text-lg md:text-xl lg:text-1xl text-white ">{testimonies[currentIndex]}</p>
          </div>
          <button
            onClick={handleNext}
            disabled={currentIndex === testimonies.length - 1}
            className="rounded-full w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 focus:outline-none text-white"
          >
            &gt;
          </button>
        </div>
      </div>

      {/*  contact */}

      <div className="text-center py-8">
        <h1 className="text-4xl font-bold text-custom-purple">Contact</h1>
        <div className="flex flex-col lg:flex-row justify-center items-center mt-8 lg:mx-auto pl-6 pr-6">
          {/* Contact Form */}
          <div className="bg-gray-200 p-6 rounded-md lg:w-1/2 w-full mb-4 lg:mb-0 mr-1 ">
            <form>
              {/* ... (rest of your form code) */}
              <div className='lg:flex lg:justify-between mb-4 '>
                <div className="mb-4 lg:w-1/2 lg:mr-2">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full border-gray-300 rounded-md p-2 focus:border-custom-purple focus:outline-none"
                    placeholder='Name'
                    required
                  />
                </div>
                <div className="mb-4 lg:w-1/2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full border-gray-300 rounded-md p-2 focus:border-custom-purple focus:outline-none"
                    placeholder='Email'
                    required
                  />
                </div>
              </div>
              <div className='lg:flex lg:justify-between mb-4'>
                <div className="mb-4 lg:w-1/2 lg:mr-2">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="w-full border-gray-300 rounded-md p-2 focus:border-custom-purple focus:outline-none"
                    placeholder='Phone number'
                    required
                  />
                </div>
                <div className="mb-4 lg:w-1/2">
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    className="w-full border-gray-300 rounded-md p-2 focus:border-custom-purple focus:outline-none"
                    required
                    placeholder='Designation'
                  />
                </div>
              </div>
              <div className="mb-4">
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full border-gray-300 rounded-md p-2 focus:border-custom-purple focus:outline-none"
                  required
                  placeholder='Message'
                ></textarea>
              </div>
              <button type="submit" className="bg-custom-purple hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">Submit</button>
            </form>
          </div>

          {/* Map */}
          <div className="lg:w-1/2 w-full mt-4 lg:mt-0 bg-transparent">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475.8470322413258!2d78.42769021467151!3d17.422521905615103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb972a58a72d15%3A0x67c75b0c7024f9b9!2sRoad%20No.%2014%2C%20Banjara%20Hills%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1704961233233!5m2!1sen!2sin"
              width="100%"
              height="350"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;