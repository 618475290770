
import React from 'react';

const jobOpenings = [
  {
    title: "Software Engineer",
    description: "We are looking for an experienced Software Engineer to join our dynamic team. Apply now!",
    requirements: [
      "Master's degree in Computer Science or related field",
      "2+ years of experience in software development",
      "Strong knowledge of JavaScript, React, Python and Node.js ",
    ],
    status: "Coming Soon",
  },
  {
    title: "Marketing Specialist",
    description: "Exciting opportunity for a Marketing Specialist. Join us and make a difference in our marketing strategies.",
    requirements: [
      "Bachelor's degree in Marketing or a related field",
      "0-1 years of experience in marketing",
      "Proven track record in developing and implementing successful marketing campaigns",
    ],
    status: "Coming Soon",
  },

  {
    title: "Content Moderator",
    description: "We are seeking a Content Moderator to ensure the quality and safety of our online content. Apply now!",
    requirements: [
      "Bachelor's degree in Communications or a related field",
      "Experience in content moderation or a similar role",
      "Strong attention to detail and communication skills",
    ],
    status: "Coming Soon",
  },
  // Add more job openings as needed
];

const Career = () => {
  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-4 text-custom-purple">Career Opportunities</h1>
      <p className="text-gray-600 mb-8">Explore exciting career opportunities at Shree Gajanana Enterprises LLP. Stay in touch with us for the latest updates on new job openings.</p>
      
      <h2 className="text-2xl font-bold mb-4 text-green-700">Coming Soon...</h2>

      <ul className="space-y-8">
        {jobOpenings.map((job, index) => (
          <li key={index} className="flex items-center border p-4 rounded-md">
            <div>
              <h2 className="text-xl font-bold mb-2">{job.title}</h2>
              <p className="text-gray-600 mb-4">{job.description}</p>
              <h3 className="text-lg font-semibold mb-2">Requirements:</h3>
              <ul className="list-disc ml-6 mb-4">
                {job.requirements.map((requirement, idx) => (
                  <li key={idx}>{requirement}</li>
                ))}
              </ul>
              <p className="text-sm text-gray-500 mb-2">{job.status}</p>
              {job.status === "Coming Soon" && (
                <p className="text-sm text-custom-purple mb-2">Stay tuned for updates on the application process.</p>
              )}
              <button className="bg-custom-purple text-white py-2 px-4 rounded-full " disabled={job.status === "Coming Soon"}>
                {job.status === "Coming Soon" ? "Coming Soon" : "Apply Now"}
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* <p className="text-lg font-semibold mt-8 text-custom-purple">Contact Information:</p>
      <ul className="list-disc ml-6">
        <li>Email: shreegajanana.ent2021@gmail.com</li>
      </ul> */}
    </div>
  );
};

export default Career;

