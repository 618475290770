import React from 'react';
import { Link } from 'react-router-dom';
import news from '../assets/news.jpg';
import google from '../assets/google.jpg';
import apple from '../assets/apple.jpg';
import newsapp from '../assets/newsapp.jpg';
import newsappscreenshots from '../assets/newsappscreenshots.png';

const NewsPage = () => {
  const closeMenus = () => {
    // Your closeMenus function logic here
  };

  return (
    <div className="bg-white mb-5">
      <header className="bg-white py-8 text-center">
        <h1 className="text-3xl font-bold text-blue-900 mb-2">Educational News App</h1>
        <p className="text-lg text-gray-700">Stay Informed, Stay Ahead!</p>
      </header>

      <main className="container mx-auto">
        <section className="bg-white ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
              <h2 className="text-2xl font-bold mb-4">About Our App</h2>
              <p className="text-lg text-gray-800 mb-6">
              Our educational news app delivers the latest news and updates tailored for learners and educators. Stay up-to-date with relevant articles and insightful analysis covering a wide range of topics including JEE (Joint Entrance Examination), UPSC (Union Public Service Commission), as well as news updates from JNTUH (Jawaharlal Nehru Technological University Hyderabad) and JNTUK (Jawaharlal Nehru Technological University Kakinada). Additionally, we provide job notifications and other valuable resources to help you excel in your academic and professional journey.
              </p>

              <div>
                <h3 className="text-xl font-bold mb-2">Featured News</h3>
                <ul className="list-disc list-inside">
                  <li><a href="#" className="text-blue-600 hover:underline">New Study Reveals Impact of Technology on Learning</a></li>
                  <li><a href="#" className="text-blue-600 hover:underline">Top 10 Educational Podcasts to Listen to Right Now</a></li>
                  <li><a href="#" className="text-blue-600 hover:underline">How to Improve Study Habits: Expert Tips</a></li>
                  <li><a href="#" className="text-blue-600 hover:underline">5 Ways to Enhance Online Learning Experience</a></li>
                  <li><a href="#" className="text-blue-600 hover:underline">The Importance of Critical Thinking in Education</a></li>

                </ul>
              </div>
            </div>

            <div className="md:col-span-1 flex flex-col justify-center items-center">
              {/* <img src={newsapp} alt="News App" className="mx-auto mb-4" style={{ maxWidth: '400px' }} /> */}
              <img src={newsappscreenshots} alt="News App" className="mx-auto mb-1" style={{ width: '500px', height: 'auto' }} />
              {/* <div className="flex flex-row"> */}
                {/* <a href="https://play.google.com/store/apps/details?id=com.studytap" target="_blank" rel="noopener noreferrer">
                  <img src={apple} alt="Google Play Store" width="200" />
                </a> */}

                {/* <a href="https://apps.apple.com/in/app/studytap/id6443762838" target="_blank" rel="noopener noreferrer">
                  <img src={google} alt="Apple App Store" width="200" />
                </a> */}
              {/* </div> */}
            </div>
          </div>
        </section>

        <section className="bg-white py-6">
        <h2 className="text-2xl font-bold mb-4 text-center text-blue-900 ">Download The App Now!</h2> 
        <div className="flex flex-row justify-center mb-2">

                <a href="https://play.google.com/store/apps/details?id=com.edstudytapnews&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                  <img src={apple} alt="Google Play Store" width="200" />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.edstudytapnews&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                  <img src={google} alt="Apple App Store" width="200" />
                </a>
          </div>
          <div className="container mx-auto text-center">
            <h2 className="text-2xl font-bold mb-4 text-blue-900">Stay Updated With the Latest Educational News</h2>
            <p className="text-lg text-gray-800 mb-6">
              Get access to breaking news, in-depth analyses, and expert opinions in the field of education. Download our app now to stay informed!
            </p>
            
          </div>
        </section>
      </main>

    </div>
  );
};

export default NewsPage;





