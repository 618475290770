import React from 'react';
import studytap from '../assets/studytap.png';
import mobile from '../assets/mobile.png'
import apple from '../assets/apple.jpg';
import google from '../assets/google.jpg';
import award from '../assets/award.jpg';
import { Link } from 'react-router-dom';
import twitter from '../assets/twitter.png';
import facebook from '../assets/facebook.png';
import linkdin from '../assets/linkdin.png';
import insta from '../assets/insta.png';
import snap from '../assets/snap.png';
import whatsapp from '../assets/whatsapp.png';


const StudyTap = () => {
  return (
    <div>
      <div className='p-4 md:p-6 lg:p-8 mx-auto'>
        <h1 className='text-4xl text-center font-bold '>Study<span className='text-blue-900'>T</span>ap</h1>
        {/* First Section */}
        {/* <div className='flex flex-col lg:flex-row sm:flex-col md:flex-col items-center justify-center'>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify ">
            <p className="text-lg">

              Study Tap is your dedicated online resource hosting the last ten years' question papers and answers for all Engineering courses at JNTUH and JNTUK-affiliated colleges. We believe in the power of practice, providing students with a consolidated platform to access and download papers, saving valuable study time. This repository not only streamlines search efforts but also enables focused study by offering insights into question types, exam patterns, and section weightages. Gain a comprehensive understanding of previous exams to enhance preparation and boost confidence for upcoming assessments.
            </p>
            <button className='mt-5'>
              <Link to="https://www.studytap.co.in/" target="_blank" rel="noopener noreferrer" className="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 rounded-md ">
                Know More &rarr;
              </Link>
            </button>
          </div>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center">
            <img src={study} alt="Left Image" className="w-full h-auto rounded-lg" />
          </div>
        </div> */}
        <div className='flex flex-col lg:flex-row sm:flex-col md:flex-col items-center justify-center'>

          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify">
            <h1 className='text-3xl font-bold text-custom-purple mb-4'>Start Your journey with StudyTap</h1>
            <p className="text-lg mb-4 text-justify">
              This app is designed for the students who are hunting to get the answers for previous years question papers for universities like JNTUH and JNTUK . With one tap students can get access to visit the previous year papers along with the solutions.So, apprentices leave all your worries to us, we are here to provide the best-in-class tools for success and to build confidence within you and take away your success key from us. Scholars!! This is just a beginning help us to give you better knowledge
            </p>
            <button className='mt-5'>
              <Link to="https://www.studytap.co.in/" target="_blank" rel="noopener noreferrer" className="bg-blue-300 hover:bg-blue-400 text-custom-purple font-bold py-2 px-4 rounded-md ">
                Know More &rarr;
              </Link>
            </button>
          </div>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center">
            <img src={studytap} alt="Left Image" className="w-3/4 h-auto rounded-lg mx-auto" />
          </div>

        </div>

        {/* Second Section */}
        <div className='flex flex-col lg:flex-row sm:flex-col md:flex-col items-center justify-center'>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center">
            <img src={mobile} alt="Left Image" className="w-full h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify">
            <h1 className='text-3xl font-bold text-custom-purple mb-4'>Download the app now</h1>
            <p className="text-lg mb-4 text-justify">
              This app is designed for the students who are hunting to get the answers for previous years question papers for universities like JNTUH and JNTUK . With one tap students can get access to visit the previous year papers along with the solutions.So, apprentices leave all your worries to us, we are here to provide the best-in-class tools for success and to build confidence within you and take away your success key from us. Scholars!! This is just a beginning help us to give you better knowledge
            </p>
            <div className=' flex flex-row '>
              <a href="https://play.google.com/store/apps/details?id=com.studytap" target="_blank" rel="noopener noreferrer">
                <img src={apple} alt="Google Play Store" width="200" />
              </a>

              <a href="https://apps.apple.com/in/app/studytap/id6443762838" target="_blank" rel="noopener noreferrer">
                <img src={google} alt="Apple App Store" width="200" />
              </a>
            </div>
          </div>
        </div>
      </div>

<div className="bg-gradient-to-br from-custom-purple to-white-600 rounded-lg">
  <div className='flex flex-col lg:flex-row sm:flex-col md:flex-col items-center justify-center'>
    <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify">
      <h1 className='text-3xl font-bold text-white mb-4'> 🎉 Exciting News Alert! 🎉</h1>
      <p className="text-lg mb-4 text-justify">
        We are thrilled to share a momentous achievement with you all! 🌟 StudyTap has been recognized for its excellence in revolutionizing education at the prestigious Education 2.0 Conference held in Dubai on February 21, 2024. Our founder, konijeti Venkata Mahesh Gupta, was honored with an esteemed award for StudyTap's contribution to empowering BTech students to excel in their exams.
        This remarkable journey wouldn't have been possible without your unwavering support and trust in StudyTap. With over 25,000 users within just one and a half years, your dedication has fueled our passion to continuously strive for excellence.
        Thank you for being an integral part of the StudyTap community. Together, we're shaping the future of education!
        Stay tuned for more exciting updates and innovations.
      </p>
    </div>
    <div className="w-full lg:w-1/4 p-4 md:p-6 lg:p-8 text-center">
      <img src={award} alt="Left Image" className="w-full h-auto rounded-lg" />
    </div>
  </div>

 
</div>
<div className="mt-12 text-center mb-10">
          <h2 className="text-3xl font-bold mb-4 text-custom-purple">Get in Touch With Us</h2>
          <p className="text-gray-700 mb-8">
            Connect with us on social media or reach out via email for any inquiries.
          </p>
          <div className="flex flex-wrap justify-center space-x-4 md:space-x-8">
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fstudy_tap" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkdin} alt="LinkedIn" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.facebook.com/people/Study-Tap/100083008248719/" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.instagram.com/studytap.official/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://wa.me/6304702631" target="_blank" rel="noopener noreferrer">
  <img src={whatsapp} alt="WhatsApp" className="w-12 h-12 mb-2 md:mb-0" />
</a>
            {/* <a href="https://www.snapchat.com" target="_blank" rel="noopener noreferrer">
              <img src={snap} alt="Snapchat" className="w-12 h-12 mb-2 md:mb-0" />
            </a> */}
          </div>
          <p className="mt-4">
            For inquiries, email us at{' '}
            <a href="mailto:info@example.com" className="text-blue-500 hover:underline">
             shreegajanana.ent2021@gmail.com
            </a>
          </p>
        </div>
    </div>
  );
}

export default StudyTap;


