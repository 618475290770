
// import React from 'react';
// import { useSpring, animated } from 'react-spring';
// import itservice1 from '../assets/itservice1.png';
// import web from '../assets/web.jpg';
// import andriod from'../assets/andriod.png';
// import apple from '../assets/apple.png';

// const ItServiceCard = ({ title, description, link, imageAlt, imageUrl }) => {
//   const styles = useSpring({
//     from: { opacity: 0, transform: 'scale(0.8)' },
//     to: { opacity: 1, transform: 'scale(1)' },
//     config: { tension: 300, friction: 10 },
//   });

//   return (
//     <animated.div style={styles}>
//       <div className="bg-white p-8 rounded-lg shadow-md transition transform hover:scale-105 hover:shadow-xl">
//         <div className="mb-0">
//           <img src={imageUrl} alt={imageAlt} className="w-full h-40 object-cover rounded-md" />
//         </div>
//         <h2 className="text-2xl font-semibold mb-4">{title}</h2>
//         <p className="text-gray-700">{description}</p>
//         <div className="mt-4">
//           <a href={link} className="text-blue-500 hover:underline">
//             {link === '#' ? 'Learn More' : 'Get Started'}
//           </a>
//         </div>
//       </div>
//     </animated.div>
//   );
// };

// const ItServices = () => {
//   return (
//     <div className="bg-gray-100 min-h-screen">
//       <div className="container mx-auto p-8">
//         <h1 className="text-4xl font-bold text-center mb-8">Our IT Services</h1>

//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {/* Web Development Section */}
//           <ItServiceCard
//             title="Web Development"
//             description="We specialize in creating responsive and scalable web applications tailored to your business needs. Our team of experts ensures cutting-edge solutions for your online presence."
//             link="#"
//             imageAlt="Web Development"
//             imageUrl={web}
//           />

//           {/* Android Development Section */}
//           <ItServiceCard
//             title="Android Development"
//             description="Our Android development team crafts cutting-edge mobile applications with a focus on user experience. We leverage the latest technologies to bring your ideas to life."
//             link="#"
//             imageAlt="Android Development"
//             imageUrl={andriod}
//           />

//           {/* Apple Development Section */}
//           <ItServiceCard
//             title="Apple Development"
//             description="From iOS app design to development, we deliver seamless experiences for Apple device users. Our team ensures that your app meets the highest standards of quality and design."
//             link="#"
//             imageAlt="Apple Development"
//             imageUrl={apple}
//           />
//         </div>
       
//       </div>
//     </div>
//   );
// };

// export default ItServices;
import React from 'react';
import { useSpring, animated } from 'react-spring';
import web from '../assets/web.jpg';
import andriod from '../assets/andriod.png';
import apple from '../assets/apple.png';
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';
import twitter from '../assets/twitter.png';
import facebook from '../assets/facebook.png';
import linkdin from '../assets/linkdin.png';
import insta from '../assets/insta.png';
import snap from '../assets/snap.png';
import whatsapp from '../assets/whatsapp.png';

const ItServiceCard = ({ title, description, link, imageAlt, imageUrl }) => {
  const styles = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 300, friction: 10 },
  });

  return (
    <animated.div style={styles} className="border border-purple-100">
      <div className="bg-white p-4 md:p-8 rounded-lg shadow-md transition transform hover:scale-105 hover:shadow-xl">
        <div className="mb-4">
          <img src={imageUrl} alt={imageAlt} className="w-full h-40 object-cover rounded-md" />
        </div>
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
        <p className="text-gray-700">{description}</p>
        <div className="mt-4 flex space-x-4">
          <a href={link} className="text-blue-500 hover:underline">
            {link === '#' ? 'Learn More' : 'Get Started'}
          </a>
        </div>
      </div>
    </animated.div>
  );
};

const ItServices = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="container mx-auto p-4 md:p-8">
        <h1 className="text-4xl font-bold text-center text-purple-900 mb-8">IT Services</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
          {/* Web Development Section */}
          <ItServiceCard
            title="Web Development"
            description="We specialize in creating responsive and scalable web applications tailored to your business needs. Our team of experts ensures cutting-edge solutions for your online presence."
            link="#"
            imageAlt="Web Development"
            imageUrl={web}
          />

          {/* Android Development Section */}
          <ItServiceCard
            title="Android Development"
            description="Our Android development team crafts cutting-edge mobile applications with a focus on user experience. We leverage the latest technologies to bring your ideas to life."
            link="#"
            imageAlt="Android Development"
            imageUrl={andriod}
          />

          {/* Apple Development Section */}
          <ItServiceCard
            title="Apple Development"
            description="From iOS app design to development, we deliver seamless experiences for Apple device users. Our team ensures that your app meets the highest standards of quality and design."
            link="#"
            imageAlt="Apple Development"
            imageUrl={apple}
          />
        </div>

        {/* New Section Below Cards */}
        <div className="mt-12 text-center mb-10">
          <h2 className="text-3xl font-bold mb-4 text-purple-900">Get in Touch With Us</h2>
          <p className="text-gray-700 mb-8">
            Connect with us on social media or reach out via email for any inquiries.
          </p>
          <div className="flex flex-wrap justify-center space-x-4 md:space-x-8">
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fstudy_tap" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkdin} alt="LinkedIn" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.facebook.com/people/Study-Tap/100083008248719/" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.instagram.com/studytap.official/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://wa.me/6304702631" target="_blank" rel="noopener noreferrer">
  <img src={whatsapp} alt="WhatsApp" className="w-12 h-12 mb-2 md:mb-0" />
</a>
            {/* <a href="https://www.snapchat.com" target="_blank" rel="noopener noreferrer">
              <img src={snap} alt="Snapchat" className="w-12 h-12 mb-2 md:mb-0" />
            </a> */}
          </div>
          <p className="mt-4">
            For inquiries, email us at{' '}
            <a href="mailto:info@example.com" className="text-blue-500 hover:underline">
             shreegajanana.ent2021@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ItServices;


