import React from 'react'

const privacy = () => {
  return (
    <section className='p-5'>
    <div className="text">
      <h2 className="text-center mt-5"><b className="text-blue-600">PRIVACY POLICY</b></h2>

      <p className="px-8 text-justify text-base">
        We care about data privacy and security. By using the Site, you agree to be bound by our Privacy Policy posted on the Site, 
        which is incorporated into these Terms of Use. Please be advised the Site is hosted in India. If you access the Site from any
        other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ 
        from applicable laws in India, then through your continued use of the Site, you are transferring your data to India, 
        and you agree to have your data transferred to and processed in India.
      </p><br/>
    </div>

    <div className="text ">
      <h2 className="text-center"><b className="text-blue-600">MODIFICATIONS AND INTERRUPTIONS</b></h2>

      <p className="px-8 text-justify text-base">
        We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. 
        However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part
        of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or 
        discontinuance of the Site.
      </p><br/>
      <p className="px-8 text-justify text-base">
        We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform 
        maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance
        of the Site. Nothing in these Terms of Use will be construed to 
        obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.
      </p><br/>
    </div>

    <div className="text">
      <h2 className="text-center"><b className="text-blue-600">OTHER T&C:</b></h2>

      <p className="px-8 text-justify text-base">
        ● The liability of content provided on the website is only for knowledge purposes. No liability is reserved on content provided by
        SHREE GAJANANA ENTERPRISES LLP
        
      </p><br/>
    </div>

    <div className="text">
      <h2 className="text-center"><b className="text-blue-600">GOVERNING LAW</b></h2>

      <p className="px-8 text-justify text-base">
        These Terms shall be governed by and defined following the laws of India. SHREE GAJANANA ENTERPRISES LLP and 
        yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute
        which may arise in connection with these terms.
      </p> <br/>
    </div>

    <div className="text">
      <h2 className="text-center"><b className="text-blue-600">DISPUTE RESOLUTION</b></h2>

      <p className="px-8 text-justify text-base">
        You agree to irrevocably submit all disputes related to Terms or the legal relationship established by this Agreement
        to the jurisdiction of the India courts. SHREE GAJANANA ENTERPRISES LLP shall also maintain the right to bring proceedings 
        as to the substance of the matter in the courts of the country where you reside or if these Terms
        are entered into in the course of your trade or profession, the state of your principal place of business.
      </p><br/>
    </div>

    <div className="text">
      <h2 className="text-center"><b className="text-blue-600">CORRECTIONS</b></h2>

      <p className="px-8 text-justify text-base">
        There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions,
        pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or 
        omissions and to change or update the information on the Site at any time, without prior notice..
      </p><br/>
    </div>
  </section>
  )
}

export default privacy
