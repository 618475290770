

import React from 'react'
import Slider from 'react-slick'; // Import the Slider component
import 'slick-carousel/slick/slick.css'; // Import the styles for the slider
import 'slick-carousel/slick/slick-theme.css'; // Import the theme styles for the slider
import bgs from '../assets/bgs.jpg'
import exportologo from '../assets/exportologo 2.png';
import studytap1 from '../assets/studytap.png';
import digital from '../assets/digital.png';
import gajanana from '../assets/gajanana.jpg';
import shree from '../assets/shree.jpg';
import studytap from '../assets/studytap.png';
import expo from '../assets/expo.png';
import study from '../assets/study.png'
import c1 from '../assets/c1.png';
import c2 from '../assets/c2.png';
import c3 from '../assets/c3.png';
import c4 from '../assets/c4.png';
import c5 from '../assets/c5.png';
import c6 from '../assets/c6.jpg'
import c7 from '../assets/c7.jpg';
import c8 from '../assets/c8.jpg';

const about = () => {
  const certificateImages = [
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Adjust the speed in milliseconds
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className=" bg-white pt-4 pb-8" >
      <h1 className="text-4xl font-bold mb-4 text-center  text-custom-purple">About Us</h1>
      <div className="text-black text-center flex flex-wrap justify-center">
        <div className="max-w-md mx-4 mb-12 md:mb-0 md:mr-4">
          <p className="text-lg mb-8  text-justify">

            Shree Gajanana Enterprises LLP, established by Konijeti Mallikarjuna Prasad, stands as a paragon of excellence in the business realm. Under the leadership of his son Konijeti Venkata Mahesh Gupta, our company is distinguished by its commitment to quality and innovation across various services. StudyTap, one of our flagship offerings, is meticulously designed to support batch students with essential resources and comprehensive exam assistance.<br /><br/>

            Beyond StudyTap, Shree Gajanana Enterprises LLP extends its expertise to diverse domains, reflecting our dynamic approach to business. Our commitment to delivering high-quality products and services remains unwavering. In an ever-evolving business landscape, our company thrives on agility and innovation, ensuring that we not only meet but exceed the expectations of our valued clientele.
          </p>

        </div>
        <div className="max-w-md mx-4">
          <p className="text-lg  text-justify">

            Exporto Enterprises LLP, a 52-year family legacy, stems from Mr. Konijeti Mallikarjuna Prasad's success with Madhu Traders in the local red chilli business. Led by his son, Konijeti Venkata Mahesh Gupta, the Founder & CEO, Exporto expands globally with a fresh vision and ambition. Together, they established Exporto Enterprises LLP, focusing on international operations.<br /> <br />

            Konijeti Venkata Mahesh Gupta's leadership prioritizes delivering top-quality products and impeccable service, combining his father's expertise and entrepreneurial spirit. Join us on this journey, building upon a strong foundation, as Exporto Enterprises LLP exemplifies excellence in global exports.
          </p>
        </div>
      </div>
     <div className="flex flex-col md:flex-row justify-center items-center mt-8 space-y-4 md:space-y-0 pl-8 pr-8">
  <img src={study} alt="Image 1" className="mx-2  md:w-50 md:h-60 w-50 h-auto rounded-md" />
  <img src={shree} alt="Image 2" className="mx-2 md:w-60 md:h-90 w-full h-auto rounded-md" />
  <img src={expo}  className="mx-2 mt-30 md:w-50 md:h-60 w-50 h-auto  rounded-md" />
</div>



      <div className="w-full text-center relative">
        <h2 className="text-4xl font-bold text-custom-purple  mt-12">Certificates</h2>
        <div className="mx-auto w-full max-w-6xl mt-8 pl-6 rounded-lg overflow-hidden relative">
          <style>
            {`
        .certificate-slider::-webkit-scrollbar {
          display: none; /* Hide scrollbar for all screen sizes */
        }
      `}
          </style>
          <Slider className="certificate-slider" {...settings}>
            {certificateImages.map((image, index) => (
              <div key={index} className="flex justify-center">
                <div className="w-full rounded-full overflow-hidden">
                  <img src={image} alt={`Certificate Image ${index + 5}`} className="w-1/2 h-auto object-cover rounded-full" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

    </div>
  )
}

export default about


// className="w-full min-h-screen bg-cover bg-no-repeat flex items-center justify-center" style={{ backgroundImage: `url(${bgs})` }}