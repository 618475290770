import React from "react";
import companylogo from "../assets/companylogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-6">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="mb-4 md:mb-8 md:w-1/4 lg:ml-0 md:ml-0 sm:ml-0 ml-0">
            <img src={companylogo} className="w-40 h-40" />
            <p className="text-lg ml-3">
              Shree Gajanana Enterprises LLP delivers four services, ensuring
              customer satisfaction through innovative and personalized
              solutions.
            </p>
          </div>
          <div className="mb-4 md:mb-8 md:w-1/4">
            <h2 className="text-xl md:text-2xl font-bold mb-2 md:mb-4 ml-3 ">
              Company info
            </h2>
            <ul className="space-y-2 ml-3">
              <li>
                <Link to="/about" className="hover:text-gray-500">
                  About
                </Link>
              </li>
              <li>
                <a href="/career" className="hover:text-gray-500">
                  Career
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-gray-500">
                  F&Q
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-8 md:w-1/4">
            <h2 className="text-xl md:text-2xl font-bold mb-2 md:mb-4 ml-3">
              Services
            </h2>
            <ul className="space-y-2 ml-3">
              <li>
                <a href="/studytap" className="hover:text-gray-500">
                  StudyTap
                </a>
              </li>
              <li>
                <a href="/exporto" className="hover:text-gray-500">
                  Exporto
                </a>
              </li>
              <li>
                <a href="/ed" className="hover:text-gray-500">
                  Studytap News
                </a>
              </li>
              <li>
                <a href="/it_services" className="hover:text-gray-500">
                  It Services
                </a>
              </li>
              <li>
                <a href="/digital_marketing" className="hover:text-gray-500">
                  Digital Marketing
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-8 md:w-1/4">
            <h2 className="text-xl md:text-2xl font-bold mb-2 md:mb-4 ml-3">
              Contact
            </h2>
            <ul className="space-y-2 ml-3">
              <ul>
                {/* <li className='py-1'>Call us on : </li>
                    <li className='py-1'>+91 6304702631</li> */}
                <li className="py-1">Email us on :</li>
                <li className="py-1">shreegajanana.ent2021@gmail.com</li>

                <div className="flex space-x-6 mt-2">
                  <a
                    href="https://www.linkedin.com/company/exportoenterprisesofficial/"
                    className="text-blue-400 dark:hover:text-white"
                  >
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M19 0H5C2.24 0 0 2.24 0 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5zm-8 19h-3V9h3v10zm-1.5-11.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm10.5 11.5h-3v-5.5c0-1.65-.4-2.5-2-2.5s-2.3.85-2.3 2.5V19h-3V9h3v1.54c.46-.84 1.6-1.54 3.3-1.54 2.4 0 4.3 1.56 4.3 4.96v5.04z" />
                    </svg>
                    <span className="sr-only">LinkedIn page</span>
                  </a>


                  <a
                    href="https://www.instagram.com/exportoenterprises.official/?igshid=MzRlODBiNWFlZA%3D%3D"
                    className="text-pink-400  dark:hover:text-white"
                  >
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Instagram page</span>
                  </a>

                  <a
                    href="https://www.facebook.com/people/ExportoEnterprisesofficial/100092317192985/?mibextid=ZbWKwL"
                    className="text-blue-400 dark:hover:text-white"
                  >
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Facebook page</span>
                  </a>
{/*         
                  <a
                    href="https://twitter.com/study_tap"
                    className="text-blue-400  dark:hover:text-white"
                  >
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    <span className="sr-only">Twitter page</span>
                  </a> */}
                </div>
              </ul>
            </ul>
          </div>
          <div className="md:w-1/4">
            <div className="flex space-x-4 mb-2 md:mb-4 ml-3">
              <ul>
                <li>
                  <h3 className="font-bold md:text-2xl mb-2 ">Address : </h3>
                </li>
                <li className="py-1"> Shree Gajanana Enterprises LLP ,</li>
                <li className="py-1"> D.No. 8-2-289/7, 3rd floor </li>
                <li className="py-1"> Road No-14,Banjara Hills,</li>
                <li className="py-1 ">Hyderabad, 500034 </li>
                <li className="py-1 ">Telangana </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex text-center justify-center">
          <Link to="/privacy" className="text-white">
            Privacy Policy
          </Link>
          <div className="mx-3 border-r border-white h-5"></div>
          {/* <h1 className="text-white">Terms and Conditions</h1> */}
          <Link to="/terms" className="text-white">
            Terms & conditions
          </Link>
        </div>
        <div className="mt-1 md:mt-8 border-t border-gray-500 pt-1 md:pt-4 flex flex-col items-center">
          {/* <h2 className="text-2xl md:text-3xl font-extrabold mb-2 md:mb-4 ml-3">Subscribe to Our Newsletter</h2>
          <p className="text-sm mb-4 md:mb-8 ml-3">Get the latest updates and news straight to your inbox.</p>
          <div className="flex flex-col items-center md:flex-row">
            <input
              type="email"
              placeholder="Your Email Address"
              className="w-full md:w-64 px-4 py-2 mb-2 md:mb-0 border border-gray-600 rounded-l focus:outline-none"
            />
            <button className="bg-purple-800 text-white px-6 py-2 rounded-r hover:bg-purple-900 focus:outline-none">
              Subscribe
            </button>
          </div> */}
        </div>
      </div>
      <div className="mt-4 md:mt-8 text-center">
        <p className="text-sm">
          &copy; Copyright 2021 Shree gajanana enterprises LLP, Hyderabad,
          Telangana - All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
