// import First from "../assets/First.jpg";
// import Second from "../assets/Second.jpg";
// import Third from "../assets/Third.jpg";

import slider1 from '../assets/slider1.jpg';
import slider2 from '../assets/slider2.png'
import slider3 from '../assets/slider3.jpg'

export default [
  {
    // title: "First Slide" ,
    // description: "This is the first slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(133).jpg",
    urls: slider1,
  },
  {
    // title: "Second Slide",
    // description: "This is the second slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(147).jpg",
    urls: slider2,
  },
  {
    // title: "Third Slide",
    // description: "This is the Third slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(102).jpg",
    urls: slider3,
  },
  {
    // title: "Fourth Slide",
    // description: "This is the Fourth slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(133).jpg",
    urls: slider1,
  },
  {
    // title: "Fifth Slide",
    // description: "This is the Fifth slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(147).jpg",
    urls: slider2,
  },
  {
    // title: "Sixth Slide",
    // description: "This is the Sixth slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(105).jpg",
    urls: slider3,
  },
  {
    // title: "Seventh Slide",
    // description: "This is the Seventh slider Image of our carousel",
    // urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(102).jpg",
    urls: slider1,
  },
];

