


// import React from 'react';
// import Slider from 'react-slick'; 
// import mobile2 from '../assets/mobile2.png';
// import apple from '../assets/apple.jpg';
// import google from '../assets/google.jpg';
// import { Link } from 'react-router-dom';
// import shipment from '../assets/shipment.png';
// import greenchilli from '../assets/greenchilli.png';
// import redchilli from '../assets/redchilli.png';
// import rice from '../assets/rice.png';
// import cowdung from '../assets/cowdung.png';
// import maze from '../assets/maze.jpg';
// import ginger from '../assets/ginger.png';
// import jagery from '../assets/jagery.png';
// import other from '../assets/other.png';
// import mirchi from '../assets/mirchi.png';



// const exporto = () => {
//   const certificateImages = [
//     greenchilli,
//     redchilli,
//     rice,
//     maze,
//     ginger,
//     jagery,
//     other,
//     cowdung,
//   ];
//     const settings = {
//       dots: true,
//       infinite: true,
//       speed: 500, // Adjust the speed in milliseconds
//       slidesToShow: 4,
//       slidesToScroll: 3,
//       autoplay: true,
//       autoplaySpeed: 2000,
//     };
//   return (
//     <div>

//       <div className='p-4 md:p-6 lg:p-8 mx-auto'>
//         <h1 className='text-4xl text-center font-bold mt-5 text-green-950'>Exporto</h1>
//         {/* First Section */}
//         <div className='flex flex-col lg:flex-row sm:flex-col md:flex-col items-center justify-center -mb-8'>
//           <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify ">
//             <p className="text-lg text-justify">
            
//             EXPORTO Enterprises, based in India, is a dynamic global trade facilitator connecting Indian markets worldwide. With a dedicated team, we provide efficient solutions for diverse importing and exporting needs, specializing in raw materials and premium Indian goods. Committed to transparency, integrity, and customer satisfaction, we foster long-term relationships through personalized service, timely communication, and reliable delivery. Whether you're a small business or a multinational corporation, EXPORTO supports your global trade journey by minimizing risks and optimizing supply chains. Contact us to explore how we can contribute to your success in the international marketplace.
//             </p>
//             <button className='mt-5'>
//               <Link to="https://exportoenterprises.com/" target="_blank" rel="noopener noreferrer" className="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 rounded-md ">
//                 Know More &rarr;
//               </Link>
//             </button>
//           </div>
//           <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center">
//             <img src={mirchi} alt="Left Image" className="w-full h-auto rounded-lg" />
//           </div>
//         </div>

//         {/* Second Section */}
//         <div className='flex flex-col lg:flex-row sm:flex-col md:flex-col items-center justify-center'>
//           <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center">
//             <img src={mobile2} alt="Left Image" className="w-full h-auto rounded-lg" />
//           </div>
//           <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify">
//             <h1 className='text-3xl font-bold text-green-950 mb-4'>Download the app now</h1>
//             <p className="text-lg mb-4 text-justify">

//             This app is designed to be your one-stop solution for exploring the awesome products we export. Meet Exporto, available on iOS and Android. It's super easy to use – just browse through our variety of products from around the globe. But that's not all! This app goes beyond just looking; it lets you place orders and keeps you in the loop with real-time updates on your shipments. Imagine having a personal assistant for all your export needs! Whether you're a curious explorer or a savvy shopper, Exporto makes it a breeze to discover, order, and stay in the know about our fantastic exports.
//             </p>
//             <div className='flex flex-row space-x-4'>
//               <a href="https://play.google.com/store/apps/details?id=com.studytap" target="_blank" rel="noopener noreferrer">
//                 <img src={apple} alt="Google Play Store" width="200" />
//               </a>

//               <a href="https://apps.apple.com/in/app/studytap/id6443762838" target="_blank" rel="noopener noreferrer">
//                 <img src={google} alt="Apple App Store" width="200" />
//               </a>
//             </div>
//           </div>
//         </div>
//         <div className="w-full text-center relative">
//   <h2 className="text-3xl font-bold text-purple-900">Products</h2>
//   <div className="mx-auto w-full max-w-6xl mt-8 pl-6 rounded-lg overflow-hidden relative">
//     <style>
//       {`
//         .certificate-slider::-webkit-scrollbar {
//           display: none; /* Hide scrollbar for all screen sizes */
//         }
//       `}
//     </style>
//     <Slider className="certificate-slider" {...settings}>
//       {certificateImages.map((image, index) => (
//         <div key={index} className="flex justify-center">
//           <div className="w-full  overflow-hidden">
//             <img src={image} alt={`Certificate Image ${index + 4}`} className="w-1/2 h-auto object-cover " />
//           </div>
//         </div>
//       ))}
//     </Slider>
//   </div>
// </div>
        
//       </div>
      
//     </div>
//   );
// }

// export default exporto;


import React from 'react';
import Slider from 'react-slick'; 
import mobile2 from '../assets/mobile2.png';
import apple from '../assets/apple.jpg';
import google from '../assets/google.jpg';
import { Link } from 'react-router-dom';
import greenchilli from '../assets/greenchilli.png';
import redchilli from '../assets/redchilli.png';
import rice from '../assets/rice.png';
import cowdung from '../assets/cowdung.png';
import maze from '../assets/maze.jpg';
import ginger from '../assets/ginger.png';
import jagery from '../assets/jagery.png';
import other from '../assets/other.png';
import mirchi from '../assets/mirchi.png';
import twitter from '../assets/twitter.png';
import facebook from '../assets/facebook.png';
import linkdin from '../assets/linkdin.png';
import insta from '../assets/insta.png';
import snap from '../assets/snap.png';
import whatsapp from '../assets/whatsapp.png';

const Exporto = () => {
  const certificateImages = [
    greenchilli,
    redchilli,
    rice,
    maze,
    ginger,
    jagery,
    other,
    cowdung,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="container mx-auto">
      <div className='p-4 md:p-6 lg:p-8'>
        <h1 className='text-4xl text-center font-bold mt-5 text-green-950'>Exporto</h1>
        
        {/* First Section */}
        <div className='flex flex-col lg:flex-row items-center justify-center -mb-8'>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify ">
            <p className="text-lg text-justify">
              EXPORTO Enterprises, based in India, is a dynamic global trade facilitator connecting Indian markets worldwide. With a dedicated team, we provide efficient solutions for diverse importing and exporting needs, specializing in raw materials and premium Indian goods. Committed to transparency, integrity, and customer satisfaction, we foster long-term relationships through personalized service, timely communication, and reliable delivery. Whether you're a small business or a multinational corporation, EXPORTO supports your global trade journey by minimizing risks and optimizing supply chains. Contact us to explore how we can contribute to your success in the international marketplace.
            </p>
            <button className='mt-5'>
              <Link to="https://exportoenterprises.com/" target="_blank" rel="noopener noreferrer" className="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-4 rounded-md ">
                Know More &rarr;
              </Link>
            </button>
          </div>
          <div className="w-full lg:w-2/4 p-4 md:p-6 lg:p-8 text-center">
            <img src={mirchi} alt="Left Image" className="w-full h-auto rounded-lg" />
          </div>
        </div>

        {/* Second Section */}
        <div className='flex flex-col lg:flex-row items-center justify-center'>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center">
            <img src={mobile2} alt="Left Image" className="w-full h-auto rounded-lg" />
          </div>
          <div className="w-full lg:w-1/2 p-4 md:p-6 lg:p-8 text-center md:text-justify">
            <h1 className='text-3xl font-bold text-green-950 mb-4'>Download the app now</h1>
            <p className="text-lg mb-4 text-justify">
              This app is designed to be your one-stop solution for exploring the awesome products we export. Meet Exporto, available on iOS and Android. It's super easy to use – just browse through our variety of products from around the globe. But that's not all! This app goes beyond just looking; it lets you place orders and keeps you in the loop with real-time updates on your shipments. Imagine having a personal assistant for all your export needs! Whether you're a curious explorer or a savvy shopper, Exporto makes it a breeze to discover, order, and stay in the know about our fantastic exports.
            </p>
            <div className='flex flex-row space-x-4'>
              <a href="https://play.google.com/store/apps/details?id=com.exporto&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                <img src={apple} alt="Google Play Store" width="200" />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.exporto&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                <img src={google} alt="Apple App Store" width="200" />
              </a>
            </div>
          </div>
        </div>

        {/* Product Slider */}
        <div className="w-full text-center relative">
          <h2 className="text-3xl font-bold text-custom-purple">Products</h2>
          <div className="mx-auto w-full max-w-6xl mt-8 pl-6 rounded-lg overflow-hidden relative">
            <style>
              {`
                .certificate-slider::-webkit-scrollbar {
                  display: none; /* Hide scrollbar for all screen sizes */
                }
              `}
            </style>
            <Slider className="certificate-slider" {...settings}>
              {certificateImages.map((image, index) => (
                <div key={index} className="flex justify-center">
                  <div className="w-full  overflow-hidden">
                    <img src={image} alt={`Certificate Image ${index + 4}`} className="w-1/2 h-auto object-cover " />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="mt-12 text-center mb-10">
          <h2 className="text-3xl font-bold mb-4 text-custom-purple">Get in Touch With Us</h2>
          <p className="text-gray-700 mb-8">
            Connect with us on social media or reach out via email for any inquiries.
          </p>
          <div className="flex flex-wrap justify-center space-x-4 md:space-x-8">
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fstudy_tap" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkdin} alt="LinkedIn" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.facebook.com/people/ExportoEnterprisesofficial/100092317192985/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.instagram.com/exportoenterprises.official/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://wa.me/7674946379" target="_blank" rel="noopener noreferrer">
  <img src={whatsapp} alt="WhatsApp" className="w-12 h-12 mb-2 md:mb-0" />
</a>

            {/* <a href="https://www.snapchat.com" target="_blank" rel="noopener noreferrer">
              <img src={snap} alt="Snapchat" className="w-12 h-12 mb-2 md:mb-0" />
            </a> */}
          </div>
          <p className="mt-4">
            For inquiries, email us at{' '}
            <a href="shreegajanana.ent2021@gmail.com" className="text-blue-500 hover:underline">
             shreegajanana.ent2021@gmail.com
            </a>
          </p>
        </div>
    </div>
  );
}

export default Exporto;
