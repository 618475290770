
import React from 'react';
import itservice1 from '../assets/itservice1.png';
import exportologo from '../assets/exportologo 2.png';
import studytap1 from '../assets/studytap.png';
import digital from '../assets/digital.png';
import { useNavigate } from 'react-router-dom';
import bgs from '../assets/bgs.jpg';
import { Link } from 'react-router-dom';

const Services = () => {
  const servicesData = [
    {
      id: 1,
      title: 'Studytap',
      description: 'Description for Service 111111111.',
      image: studytap1, // Replace with the path to your service image
      link: '/studytap', // Replace with the path to the page for Service 1
    },
    {
      id: 2,
      title: 'Exporto',
      description: 'Description for Service 2.',
      image: exportologo, // Replace with the path to your service image
      link: '/exporto', // Replace with the path to the page for Service 2
    },
    {
      id: 3,
      title: 'It Service',
      description: 'Description for Service 3.',
      image: itservice1, // Replace with the path to your service image
      link: '/it_services', // Replace with the path to the page for Service 3
    },
    {
      id: 4,
      title: 'Digital Marketing',
      description: 'Description for Service 4.',
      image: digital, // Replace with the path to your service image
      link: '/digital_marketing', // Replace with the path to the page for Service 4
    },
  ];
  const navigate = useNavigate();
  return (
    <>

      {/* services */}
      <section className="py-6 lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 md:gap-6 sm:grid sm:grid-cols-1 sm:gap-4 pl-4 pr-4">
  <div className="lg:col-span-2 md:col-span-2 sm:col-span-1 text-center mb-6">
    <h2 className="text-3xl md:text-4xl lg:text-4xl font-bold text-purple800 text-purple-900">
      Our Services
    </h2>
  </div>
  {servicesData.map((service) => (
    <div
      key={service.id}
      className="lg:col-span-1 md:col-span-1 sm:col-span-1 mb-8 flex flex-col lg:flex-row items-center bg-white rounded-md shadow-lg shadow-gray-400 p-6"
    >
      <img
        src={service.image}
        alt={service.title}
        className="w-full lg:w-64 h-48 object-cover mb-4 lg:mb-0 lg:mr-4"
      />
      <div className="text-center lg:text-left lg:flex-1">
        <h3 className="text-xl font-bold mb-2">{service.title}</h3>
        <p className="text-gray-500 mb-4">{service.description}</p>
        <Link to={service.link}>
          <button className="bg-purple-800 text-white py-2 px-4 rounded-full hover:bg-purple-950 focus:outline-none focus:shadow-outline">
            Know More &rarr;
          </button>
        </Link>
      </div>
    </div>
  ))}
</section>
    </>
  );
};

export default Services;



