
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './components/footer'
import Topnav from './components/topnav'
import Navbar from './components/navbar'
// import Slider from './components/Slider'
import Home from './pages/home'
import About from './pages/about'
import Contact from './pages/contact'
import Services from './pages/services'
import Studytap from './pages/studytap'
import Exporto from './pages/exporto'
import It_services from './pages/it_services'
import Digital_marketing from './pages/digital_marketing'
import Carrer from './pages/carrer'
import Ed from './pages/ed'
import Whatsappchatbox from  './components/whatsappchatbox'
// import Contacticon from './components/contacticon'
import Privacy from './pages/privacy'
import Terms from './pages/terms'
const App = () => {
  return (
    <>
      <BrowserRouter>
        <Topnav />
        <Navbar />
        
        <Routes>

          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/career" element={<Carrer />} />

          <Route exact path="/studytap" element={<Studytap />} />
          <Route exact path="/exporto" element={<Exporto />} />
          <Route exact path="/it_services" element={<It_services />} />
          <Route exact path="/digital_marketing" element={<Digital_marketing />} /> 
          <Route exact path="/ed" element={<Ed />} /> 
          <Route exact path="/privacy" element={<Privacy/> }/>
          <Route exact path="/terms" element={<Terms/> }/>

        </Routes>

        <Footer />
        {/* <Contacticon/> */}
        <Whatsappchatbox/>
      </BrowserRouter>
    </>


  );
};

export default App