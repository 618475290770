
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';


// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className='bg-black h-14 sticky top-6 z-50' >
//       <div className='container mx-auto py-2 px-2 md:px-2  justify-between flex items-center relative'>
//         <div>
//           {/* <img
//             src={logo}
//             alt="Company Logo"
//             className="w-32 md:w-32 object-contain mr-24"
//           /> */}
//         </div>
//         <div className='md:hidden'>
//           <button onClick={toggleMenu} className='outline-none focus:outline-none  '>
//             {isOpen ? (
//               <svg
//                 className="w-6 h-6 "
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             ) : (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M4 6h16M4 12h16m-7 6h7"
//                 />
//               </svg>
//             )}
//           </button>
//         </div>
//         <div className={`md:flex ${isOpen ? 'block' : 'hidden'} md:block absolute md:relative top-full md:top-0 left-0 w-full md:w-auto bg-white z-20 py-2 md:py-0 md:bg-transparent`}>
//           <ul className='flex flex-col md:flex-row items-center md:items-baseline md:space-x-4'>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500"><Link to='/' onClick={toggleMenu} className='text-white'>Home</Link></li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500"><Link to='/about' onClick={toggleMenu} className='text-white'>About</Link></li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500"><Link to='/services' onClick={toggleMenu} className='text-white'>Services</Link></li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500"><Link to='/career' onClick={toggleMenu} className='text-white'>Career </Link></li> 
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500"><Link to='/contact' onClick={toggleMenu} className='text-white'>Contact</Link></li> 
//            </ul>
//         </div>
//       </div> 
//     </nav>
//   );
// };

// export default Navbar;


// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className='bg-black h-14 sticky top-6 z-50'>
//       <div className='container mx-auto py-2 px-2 md:px-2 justify-between flex items-center relative'>
//         <div></div>
//         <div className='md:hidden'>
//           <button onClick={toggleMenu} className='outline-none focus:outline-none'>
//             {isOpen ? (
//               <svg
//                 className="w-6 h-6 "
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             ) : (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M4 6h16M4 12h16m-7 6h7"
//                 />
//               </svg>
//             )}
//           </button>
//         </div>
//         <div className={`md:flex ${isOpen ? 'block' : 'hidden'} md:block absolute md:relative top-full md:top-0 left-0 w-full md:w-auto bg-white z-20 py-2 md:py-0 md:bg-transparent`}>
//           <ul className='flex flex-col md:flex-row items-center md:items-baseline md:space-x-4'>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500">
//               <Link to='/' onClick={toggleMenu} className={`text-${isOpen ? 'black' : 'white'}`}>Home</Link>
//             </li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500">
//               <Link to='/about' onClick={toggleMenu} className={`text-${isOpen ? 'black' : 'white'}`}>About</Link>
//             </li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500">
//               <Link to='/services' onClick={toggleMenu} className={`text-${isOpen ? 'black' : 'white'}`}>Services</Link>
//             </li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500">
//               <Link to='/career' onClick={toggleMenu} className={`text-${isOpen ? 'black' : 'white'}`}>Career </Link>
//             </li>
//             <li className="text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500">
//               <Link to='/contact' onClick={toggleMenu} className={`text-${isOpen ? 'black' : 'white'}`}>Contact</Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;



// import React, { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const location = useLocation();

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className='bg-black h-14 sticky top-6 z-50'>
//       <div className='container mx-auto py-2 px-2 md:px-2 justify-between flex items-center relative'>
//         <div></div>
//         <div className='md:hidden'>
//           <button onClick={toggleMenu} className='outline-none focus:outline-none'>
//             {isOpen ? (
//               <svg
//                 className="w-6 h-6 "
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             ) : (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M4 6h16M4 12h16m-7 6h7"
//                 />
//               </svg>
//             )}
//           </button>
//         </div>
//         <div className={`md:flex ${isOpen ? 'block' : 'hidden'} md:block absolute md:relative top-full md:top-0 left-0 w-full md:w-auto bg-white z-20 py-2 md:py-0 md:bg-transparent`}>
//           <ul className='flex flex-col md:flex-row items-center md:items-baseline md:space-x-4'>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/' ? 'border-purple-500' : ''}`}>
//               <Link to='/' onClick={toggleMenu} className='text-white'>Home</Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/about' ? 'border-purple-500' : ''}`}>
//               <Link to='/about' onClick={toggleMenu} className='text-white'>About</Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/services' ? 'border-purple-500' : ''}`}>
//               <Link to='/services' onClick={toggleMenu} >Services</Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/career' ? 'border-purple-500' : ''}`}>
//               <Link to='/career' onClick={toggleMenu} className='text-white'>Career </Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/contact' ? 'border-purple-500' : ''}`}>
//               <Link to='/contact' onClick={toggleMenu} className='text-white'>Contact</Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;



// import React, { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const location = useLocation();

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const closeMenu = () => {
//     setIsOpen(false);
//   };

//   return (
//     <nav className='bg-black h-14 sticky top-6 z-50'>
//       <div className='container mx-auto py-2 px-2 md:px-2 justify-between flex items-center relative'>
//         <div></div>
//         <div className='md:hidden'>
//           <button onClick={toggleMenu} className='outline-none focus:outline-none'>
//             {isOpen ? (
//               <svg
//                 className="w-6 h-6 "
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             ) : (
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke={toggleMenu ? "white" : "currentColor"}
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M4 6h16M4 12h16m-7 6h7"
//                 />
//               </svg>
//             )}
//           </button>
//         </div>
//         <div className={`md:flex ${isOpen ? 'block' : 'hidden'} md:block absolute md:relative top-full md:top-0 left-0 w-full md:w-auto bg-black z-20 py-2 md:py-0 md:bg-transparent`}>
//           <ul className='flex flex-col md:flex-row items-center md:items-baseline md:space-x-4'>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/' ? 'border-purple-500' : ''}`}>
//               <Link to='/' onClick={closeMenu} className='text-white'>Home</Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/about' ? 'border-purple-500' : ''}`}>
//               <Link to='/about' onClick={closeMenu} className='text-white'>About</Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/services' ? 'border-purple-500' : ''}`}>
//               <Link to='/services' onClick={closeMenu} >Services</Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/career' ? 'border-purple-500' : ''}`}>
//               <Link to='/career' onClick={closeMenu} className='text-white'>Career </Link>
//             </li>
//             <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-purple-500 ${location.pathname === '/contact' ? 'border-purple-500' : ''}`}>
//               <Link to='/contact' onClick={closeMenu} className='text-white'>Contact</Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;



import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import companylogo from '../assets/companylogo.jpg'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setServicesDropdownOpen(false); // Close services dropdown when main menu is toggled
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const closeMenus = () => {
    setIsOpen(false);
    setServicesDropdownOpen(false);
  };

  return (
    <nav className='bg-black h-14 sticky top-6 z-50  shadow-lg shadow-gray-700'>
      
      <div className='container mx-auto py-2 px-2 md:px-2 justify-between flex items-center relative'>
              <div>
           <img
             src={companylogo}
             alt="Company Logo"
             className="w-10 md:w-10 object-contain mr-24"
           /> 
       </div>
        <div className='md:hidden'>
          <button onClick={toggleMenu} className='outline-none focus:outline-none'>
            {isOpen ? (
              <svg
                className="w-6 h-6 "
                fill="none"
                stroke={toggleMenu ? "white" : "currentColor"}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke={toggleMenu ? "white" : "currentColor"}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
        <div className={`md:flex ${isOpen ? 'block' : 'hidden'} md:block absolute md:relative top-full md:top-0 left-0 w-full md:w-auto bg-black z-20 py-2 md:py-0 md:bg-transparent`}>
          <ul className='flex flex-col md:flex-row items-center md:items-baseline md:space-x-4'>
            <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-custom-purple ${location.pathname === '/' ? 'border-purple-500' : ''}`}>
              <Link to='/' onClick={closeMenus} className='text-white'>Home</Link>
            </li>
            <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-custom-purple ${location.pathname === '/about' ? 'border-purple-500' : ''}`}>
              <Link to='/about' onClick={closeMenus} className='text-white'>About</Link>
            </li>

            {/* Services Dropdown */}
            <li className={`relative group ${location.pathname === '/services' ? 'border-custom-purple' : ''}`}>
           
                <button onClick={toggleServicesDropdown} className={`text-white flex hover:text-white py-2 px-3 border-b-2 border-transparent focus:outline-none ${isServicesDropdownOpen ? 'border-custom-purple' : ''}`}>
                  Services
                  <svg
                    className={`w-4 h-4 ml-1 mt-2 ${isServicesDropdownOpen ? 'transform rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
             

              {isServicesDropdownOpen && (
                <ul className="absolute top-full left-0 bg-black text-white p-7 border border-custompurple rounded-md ">
                  <li>
                    <Link to='/studytap' onClick={closeMenus} className='text-white'>StudyTap</Link>
                  </li>
                  <li>
                    <Link to='/exporto' onClick={closeMenus} className='text-white'>Exporto</Link>
                  </li>
                  <li>
                    <Link to='/ed' onClick={closeMenus} className='text-white '>Studytap News</Link>
                  </li>
                  <li>
                    <Link to='/it_services' onClick={closeMenus} className='text-white'>ItServices</Link>
                  </li>
                  <li>
                    <Link to='/digital_marketing' onClick={closeMenus} className='text-white '>DigitalMarketing</Link>
                  </li>
               
                  {/* <li>
                    <Link to='/digital_marketing' onClick={closeMenus} className='text-white '>Trading View</Link>
                  </li> */}
                </ul>
              )}
            </li>

            <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-custompurple ${location.pathname === '/career' ? 'border-purple-500' : ''}`}>
              <Link to='/career' onClick={closeMenus} className='text-white'>Career </Link>
            </li>
            <li className={`text-white hover:text-white py-2 px-3 border-b-2 border-transparent hover:border-custompurple ${location.pathname === '/contact' ? 'border-purple-500' : ''}`}>
              <Link to='/contact' onClick={closeMenus} className='text-white'>Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

