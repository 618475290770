import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const TopNav = () => {
  const phoneNumber = '6304702631';
  const location = 'Hyderabad';

  return (
    <div className='bg-white w-full sticky top-0 z-50'>
      <div className='flex justify-between'>
        <a href={`tel:${phoneNumber}`} className='text-custom-purple flex items-center ml-4'>
          <FontAwesomeIcon icon={faPhone} />
          <span className='ml-2'>{phoneNumber}</span>
        </a>

        <div className='text-custom-purple flex items-center'>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <span className='ml-2 mr-7'>{location}</span>
        </div>
      </div>
    </div>
  );
};

export default TopNav;


