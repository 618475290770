import React from 'react';
import digital1 from '../assets/digital1.png';
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa';
import twitter from '../assets/twitter.png';
import facebook from '../assets/facebook.png';
import linkdin from '../assets/linkdin.png';
import insta from '../assets/insta.png';
import snap from '../assets/snap.png';
import whatsapp from '../assets/whatsapp.png';
import { Link } from 'react-router-dom';

const DigitalMarketing = () => {
  return (
    <div>
      <div className="container mx-auto mt-8 p-8 lg:flex lg:items-start lg:justify-between">
        <div className="lg:w-1/2 lg:pr-8">
          <h1 className="text-4xl mb-4 font-bold">
            <span className='text-orange-500'>D</span>igital <span className='text-blue-500'>M</span>arketing
          </h1>
          <p className="mb-4">
            In our digital marketing strategy, we use calls for personalized communication, leverage social media for community engagement, and embrace virtual face-to-face interactions through meetings and webinars. This approach ensures a strong online presence, promoting our offerings and building meaningful connections with our audience.
            <ul className="list-disc list-inside">
              <li className="mb-2">Tailored calls for support</li>
              <li className="mb-2">Compelling content for interaction</li>
              <li className="mb-2">Webinars for genuine connections</li>
              <li className="mb-2">Targeted product promotion</li>
              <li className="mb-2">Build customer loyalty</li>
            </ul>
          </p>
          <Link to="/contact">
              <button className="bg-blue-500 text-white px-4 py-2 rounded-full">
              Contact Us
              </button>
            </Link>
        </div>
        <div className="lg:w-1/2 lg:pl-8 mt-12">
          <img src={digital1} alt="Digital Marketing" className="w-full h-auto" />
        </div>
      </div>
      {/* New Section Below Cards */}
      <div className="mt-12 text-center mb-10">
          <h2 className="text-3xl font-bold mb-4 text-purple-900">Get in Touch With Us</h2>
          <p className="text-gray-700 mb-8">
            Connect with us on social media or reach out via email for any inquiries.
          </p>
          <div className="flex flex-wrap justify-center space-x-4 md:space-x-8">
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fstudy_tap" target="_blank" rel="noopener noreferrer">
              <img src={twitter} alt="Twitter" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkdin} alt="LinkedIn" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.facebook.com/people/ExportoEnterprisesofficial/100092317192985/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="Facebook" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://www.instagram.com/exportoenterprises.official/" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="Instagram" className="w-12 h-12 mb-2 md:mb-0" />
            </a>
            <a href="https://wa.me/7674946379" target="_blank" rel="noopener noreferrer">
  <img src={whatsapp} alt="WhatsApp" className="w-12 h-12 mb-2 md:mb-0" />
</a>

            {/* <a href="https://www.snapchat.com" target="_blank" rel="noopener noreferrer">
              <img src={snap} alt="Snapchat" className="w-12 h-12 mb-2 md:mb-0" />
            </a> */}
          </div>
          <p className="mt-4">
            For inquiries, email us at{' '}
            <a href="mailto:info@example.com" className="text-blue-500 hover:underline">
             shreegajanana.ent2021@gmail.com
            </a>
          </p>
        </div>
    </div>
  );
};

export default DigitalMarketing;





